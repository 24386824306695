import React, { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, ButtonToolbar, Col, Container, FlexboxGrid, Loader, Panel } from 'rsuite';
import { useTests } from 'hooks/useTests';
import { useHistory, useParams } from 'react-router-dom';
import { QuestionsSheet } from 'components/QuestionsSheet';
import { ROUTES } from '../App';

interface TestPageProps {

}

export const TestPage: React.FC<TestPageProps> = () => {
  const tests = useTests();
  const params = useParams<{ id: string }>();
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const history = useHistory();
  const [test, setTest] = useState<any>();

  useEffect(() => {
    tests.getById(params.id).then((res) => {
      setTest(res);
      const initialAnswers = res.list.reduce((acc: any, item: any) => {
        if (item.userAnswer) {
          acc[item.id] = item.userAnswer;
        }

        return acc;
      }, {});

      setAnswers(initialAnswers);
    });

  }, []);

  const handler = (id: string, answer: string) => {
    setAnswers({
      ...answers,
      [id]: answer,
    });
  };

  if (tests.loading || !test) {
    return (
      <Container>
        <Panel>
          <Loader speed="fast" content="The Force will be with you. Always." vertical center/>
        </Panel>
      </Container>
    );
  }

  return (
    <FlexboxGrid justify="center" align="middle">
      <FlexboxGrid.Item componentClass={Col} xs={24} sm={24} md={14}>
        <Container style={{padding: 20}}>
          <QuestionsSheet
            level={test.level}
            list={test.list}
            complete={test.complete}
            answers={answers}
            onClick={handler}
          />

          <Panel>
            <FlexboxGrid justify="center" align="middle">
              <FlexboxGrid.Item colspan={8}>
                <ButtonToolbar>
                  <Button disabled={test.complete} block onClick={async () => {

                    // eslint-disable-next-line no-restricted-globals
                    const isSave = confirm('Save?');
                    if (isSave) {
                      const result = test.list.map((item: any) => {
                        return {
                          ...item,
                          userAnswer: answers[item.id] || null,
                        };
                      });

                      await tests.update(`${params.id}/list`, result);
                      await tests.update(`${params.id}/complete`, true);
                      Alert.success('Saved!');
                    }

                  }}> Save </Button>
                  <Button block onClick={() => history.push(ROUTES.HOME)}> go to home page</Button>
                </ButtonToolbar>
              </FlexboxGrid.Item>

            </FlexboxGrid>
          </Panel>
        </Container>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );

};