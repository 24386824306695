export const firebaseConfig = {
  apiKey: "AIzaSyDJEztZ0ZY4q1j6sujqslKFwFeVW--ioWo",
  authDomain: "maybe-english.firebaseapp.com",
  databaseURL: "https://maybe-english-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "maybe-english",
  storageBucket: "maybe-english.appspot.com",
  messagingSenderId: "226507013042",
  appId: "1:226507013042:web:fe2240bc25b092b80a42ab",
  measurementId: "G-QLCV960Y37"
};
