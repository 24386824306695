import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import firebase from 'firebase/app';

export interface RowEntity {
  id: string;
  //  date in ISO format
  createdAt: string;
}

export interface useTableInstance<T> {
  create: (payload: Record<string, any>) => Promise<string | undefined>;
  update: (id: string, payload: Record<string, any>) => Promise<any>;
  list: T[];
  remove: (id: string) => Promise<any>
  getById: (id: string) => Promise<T | undefined>
  selected: T | undefined
  loading: boolean;
  firebase: any
}

export const useTable = <T = any>(tableName: string): useTableInstance<T> => {
  const [list, setList] = useState<T[]>([]);
  const [selected, setSelected] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!firebase) {
      console.error('Firebase must be present');
    }
  }, []);

  const create = useCallback(async (payload: Record<string, any>): Promise<string | undefined> => {
    try {
      const id = nanoid(8);
      console.info(`Create entity with id - ${id}`);
      await firebase.database().ref(`${tableName}/${id}`).set({
        ...payload,
        id,
        createdAt: new Date().toISOString(),
      });

      return id;
    } catch (e) {
      console.error('Create error - ' + e);
    }
  }, [tableName]);

  const getById = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const response = await firebase.database().ref(`${tableName}/${id}`).get();
      const payload = response.val();
      setSelected(payload);
      return payload;
    } catch (e) {
      console.error(`Fail to get getById ${tableName}/${id}`);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [tableName]);

  const update = useCallback((id: string, payload: any) => {
    return firebase.database().ref(`${tableName}/${id}`).set(payload);
  }, [tableName]);

  const remove = useCallback((id: string) => {
    return firebase.database().ref(`${tableName}/${id}`).remove();
  }, [tableName]);

  useEffect(() => {
    const ref = firebase.database().ref(tableName).orderByChild('createdAt');

    const handler = (snapshot: any) => {
      const list: any = [];
      snapshot.forEach((childSnapshot: any) => {
        const dt = childSnapshot.val();
        list.push(dt);
      });

      setList(list);
    };

    ref.on('value', handler);

    return () => ref.off('value', handler);

  }, [tableName]);

  return {
    create,
    update,
    list,
    remove,
    getById,
    selected,
    loading,
    firebase
  };
};