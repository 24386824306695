import { useTable } from './useTable';
import { useCallback, useEffect } from 'react';

export const useQuestions = () => {
  const tableName = 'questions';
  const crud = useTable(tableName);

  const findByLevel = useCallback((level: string): Promise<any[]> => {
    const ref = crud.firebase.database().ref(tableName);
    return ref
      .orderByChild('level')
      .equalTo(level)
      .once('value')
      .then((snapshot: any) => {
        let result: any[] = [];
        snapshot.forEach((data: any) => {
          result.push(data.val());
        });
        return result;
      }).catch((e: any) => {
        console.error('Error: findByLevel - ' + level);
        return [];
      });
  }, [crud.firebase]);

  return {
    ...crud,
    findByLevel,
  }

}