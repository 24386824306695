import React, { useEffect, useState } from 'react';
import { Button, Container, Panel } from 'rsuite';

interface QuestionsSheetProps {
  level: string;
  list: any[];
  onClick?: (id: string, ans: string) => void;
  answers?: Record<string, string>;
  complete?: boolean;
}

const prefix = 'answer_';
const ids = [1, 2, 3, 4].map(id => prefix + id);

export const QuestionsSheet: React.FC<QuestionsSheetProps> = ({level, list, onClick, complete, ...props}) => {

  const [answers, setAnswers] = useState<Record<string, string>>(props.answers || {});

  useEffect(() => {
    const initialAnswers = list.reduce((acc: any, item: any) => {
      if (item.userAnswer) {
        acc[item.id] = item.userAnswer;
      }

      return acc;
    }, {});

    setAnswers(initialAnswers);
  }, [list]);

  useEffect(() => {
    if (props.answers && Object.keys(props.answers).length) {
      setAnswers(props.answers);
    }
  }, [props.answers]);

  return (
    <Panel bordered header={level}>
      {list.map((item: any, index: number) => {
        const status = item.userAnswer === item.rightAnswer ? '✅' : '❌';

        return (
          <Panel key={item.id} header={`${index + 1}. ${item.question} ${complete ? status : ''}` }>
            <div className="answers__container">
              {ids.map((answerId) => {
                const isSelected = answers[item.id] === answerId;

                // userAnswer
                // rightAnswer

                return (
                  <Button
                    block
                    key={answerId}
                    onClick={() => onClick && onClick(item.id, answerId)}
                    {...getAppearance({item, answerId, complete, isSelected})}
                  >
                    {item[answerId]}
                  </Button>
                );
              })}
            </div>
            <hr/>
            <br/>
          </Panel>
        );
      })}
    </Panel>

  );
};

const getAppearance = ({item, answerId, complete, isSelected}: any): Record<string, string | boolean> => {

  if (complete) {
    const correct = item.userAnswer === item.rightAnswer && item.userAnswer === answerId;

    if (correct) {
      return {
        appearance: 'primary',
        color: 'green',
      };
    }

    if (answerId === item.rightAnswer) {
      return {
        appearance: 'primary',
        color: 'green',
      };
    }

    if (answerId === item.userAnswer) {
      return {
        appearance: 'primary',
        color: 'orange',
      };
    }

    return {
      disabled: true
    }
  }

  return {
    appearance: isSelected ? 'primary' : 'subtle',
    color: 'orange'
  };


};