import { useTable } from './useTable';
import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import firebase from 'firebase';
import { useAuth } from '../contexts/authContext';

export const useTests = () => {
  const tableName = 'tests';
  const auth = useAuth();
  const crud = useTable(tableName);

  const [list, setList] = useState<any[]>([]);
  const [all, setAll] = useState<any[]>([]);

  const create = useCallback(async (data: Record<string, any>): Promise<string | undefined> => {
    try {
      const id = nanoid(8);
      console.info(`Create entity with id - ${id}`);

      await firebase.database().ref(`${tableName}/${auth.user.uid}/meta`).set({
        displayName: auth.user.displayName,
        email: auth.user.email,
        photoURL: auth.user.photoURL,
        createdAt: new Date().toISOString(),
      });

      await firebase.database().ref(`${tableName}/${auth.user.uid}/data/${id}`).set({
        id,
        ...data,
      });

      return id;
    } catch (e) {
      console.error('Create error - ' + e);
      throw Error(e);
    }
  }, [tableName, auth]);

  const getById = useCallback((id: string): Promise<any> => {
    return crud.getById(`${auth.user.uid}/data/${id}`)
  }, [crud, auth]);

  const update = useCallback((id: string, payload: any): Promise<any> => {
    return crud.update(`${auth.user.uid}/data/${id}`, payload)
  }, [crud, auth]);


  useEffect(() => {
    if (auth.user) {
      const ref = firebase.database().ref(`${tableName}/${auth.user.uid}/data`).orderByChild('createdAt');

      const handler = (snapshot: any) => {
        const list: any = [];
        snapshot.forEach((childSnapshot: any) => {
          const dt = childSnapshot.val();
          list.push(dt);
        });

        setList(list);
      };

      ref.on('value', handler);

      return () => ref.off('value', handler);
    }
  }, [tableName, auth]);

  useEffect(() => {
    if (auth.user) {
      const ref = firebase.database().ref(tableName).orderByChild('createdAt');

      const handler = (snapshot: any) => {
        const list: any = [];
        snapshot.forEach((childSnapshot: any) => {
          const dt = childSnapshot.val();
          list.push(dt);
        });

        setAll(list);
      };

      ref.on('value', handler);

      return () => ref.off('value', handler);
    }
  }, [tableName, auth])

  return {
    ...crud,
    create,
    list,
    getById,
    update,
    all,
  };
};