import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, Container, Drawer, FlexboxGrid, List, Loader, Panel, PanelGroup, Tag } from 'rsuite';
import { useTests } from 'hooks/useTests';
import { QuestionsSheet } from 'components/QuestionsSheet';

interface ReviewPageProps {

}

const UserMeta = ({displayName, photoURL}: any) => {
  return (
    <FlexboxGrid align="middle">
      <FlexboxGrid.Item style={{paddingRight: 10}}>
        <Avatar src={photoURL} circle/>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item>
        {displayName}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};
export const ReviewPage: React.FC<ReviewPageProps> = () => {
  const tests = useTests();

  const [show, setShow] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>();

  if (!tests.all.length) {
    return (
      <Container>
        <Container>
          <Panel>
            <Loader speed="fast" content="“Power! Unlimited power!”" vertical center/>
          </Panel>
        </Container>
      </Container>
    );
  }

  return (
    <Container style={{padding: 20}}>
      <PanelGroup accordion defaultActiveKey={1} bordered>
        {tests.all.map((user: any, index) => {
          return (
            <Panel eventKey={index + 1} key={user.meta.email} header={<UserMeta {...user.meta}/>}>
              <List>
                {typeof user.data === 'object' && Object.values(user.data).map((item: any, index) => {
                  const rightAnswers = item.list.reduce((acc: number, q: any) => {
                    if (q.rightAnswer === q.userAnswer) {
                      acc++;
                    }
                    return acc;
                  }, 0);

                  return (
                    <List.Item key={item.id} index={index}>
                      <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={6}>{item.level}</FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                          {item.complete && <Tag color="green">completed</Tag>}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                          {item.complete && (
                            <span>
                              {rightAnswers} / {item.list.length}
                          </span>
                          )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                          <Button onClick={() => {
                            setShow(true);
                            setCurrent({
                              test: item,
                              user: user.meta
                            });
                          }} appearance="ghost">open</Button>
                        </FlexboxGrid.Item>
                      </FlexboxGrid>
                    </List.Item>
                  );
                })}
              </List>
            </Panel>
          );
        })}
      </PanelGroup>
      <Drawer
        full
        placement="right"
        show={show}
        onHide={() => setShow(false)}
      >
        {
          current && (
            <React.Fragment>
              <Drawer.Header>
                <Drawer.Title>{ current.user.displayName } - { current.test.level }</Drawer.Title>
              </Drawer.Header>
              <Drawer.Body>
                <QuestionsSheet level="" list={current.test.list} complete />
              </Drawer.Body>
              <Drawer.Footer>
                <Button onClick={() => setShow(false)} appearance="primary">
                  Confirm
                </Button>
                <Button onClick={() => setShow(false)} appearance="subtle">
                  Cancel
                </Button>
              </Drawer.Footer>
            </React.Fragment>
          )
        }
      </Drawer>
    </Container>
  );
};