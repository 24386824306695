import React from 'react';
import { Col } from 'rsuite';

export const Logo = () => {
  return (
    <React.Fragment>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="30" viewBox="0 0 100 30" fill="none" className="logo">
            <path
              d="M45.8528 29.2041C43.0277 30.8268 39.4165 29.8642 37.7859 27.0529L27.2194 8.83723C25.5888 6.0259 26.5561 2.43228 29.3812 0.809648C32.2063 -0.812982 35.8175 0.149595 37.4481 2.96093L48.0146 21.1766C49.6452 23.9848 48.6779 27.5815 45.8528 29.2041Z"
              fill="white"/>
            <path
              d="M61.7778 29.1828C58.9527 30.8054 55.3415 29.8428 53.7109 27.0315L43.1444 8.81588C41.5138 6.00454 42.4811 2.41092 45.3062 0.788286C48.1313 -0.834344 51.7425 0.128233 53.3731 2.93957L63.9396 21.1552C65.5702 23.9665 64.6029 27.5602 61.7778 29.1828Z"
              fill="#FF640C"/>
            <path
              d="M32.0834 21.1718L21.9468 3.70183C21.4708 2.52534 20.6172 1.49248 19.4257 0.807985C18.6027 0.334336 17.7153 0.0898717 16.8278 0.0348672C16.8186 0.0348672 16.8063 0.0318114 16.7971 0.0318114C16.6774 0.0256998 16.5576 0.022644 16.4348 0.022644C16.315 0.022644 16.1953 0.0256998 16.0724 0.0318114C16.0632 0.0318114 16.0509 0.0318114 16.0417 0.0348672C15.1543 0.0868158 14.2668 0.334336 13.4438 0.807985C12.2555 1.49248 11.3987 2.52534 10.9227 3.70183L0.79227 21.1718C-0.838309 23.9832 0.128983 27.5768 2.95409 29.1994C5.7792 30.8221 9.39043 29.8595 11.021 27.0481L16.4378 17.7127L21.8547 27.0481C23.4853 29.8595 27.0965 30.8221 29.9216 29.1994C32.7467 27.5768 33.714 23.9832 32.0834 21.1718Z"
              fill="white"/>
            <path
              d="M88.343 0.824923C85.5179 -0.797707 81.9066 0.16487 80.2761 2.97621L74.7947 12.4217L69.3073 2.96398C67.6767 0.152647 64.0655 -0.80993 61.2403 0.8127C58.4152 2.43533 57.4479 6.02895 59.0785 8.84029L69.6451 27.0559C70.4957 28.5196 71.8806 29.4761 73.4129 29.8336C73.5019 29.855 73.591 29.8734 73.6831 29.8917C73.7415 29.9039 73.8029 29.9131 73.8612 29.9223C76.2165 30.3073 78.6731 29.2469 79.9383 27.0681L90.5048 8.85251C92.1385 6.04423 91.1681 2.44755 88.343 0.824923Z"
              fill="#FF640C"/>

        </svg>
      <Col>
         <span className="logo-text">
            maybe.<span className="brand">english</span>
        </span>
      </Col>

    </React.Fragment>
  )
}