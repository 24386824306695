import { useQuestions } from 'hooks/useQuestions';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, ButtonToolbar, Col, Container, FlexboxGrid, Icon, List, Panel, Tag } from 'rsuite';
import { useTests } from '../hooks/useTests';
import { useAuth } from '../contexts/authContext';
import { ROUTES } from '../App';

interface HomePageProps {

}

enum LEVELS {
  advanced = 'advanced',
  beginner = 'beginner',
  elementary = 'elementary',
  intermediate = 'intermediate',
  pre_intermediate = 'pre-intermediate',
  upper_intermediate = 'upper-intermediate',
};

const levels: Record<string, string> = {
  elementary: LEVELS.elementary,
  beginner: LEVELS.beginner,
  pre_intermediate: LEVELS.pre_intermediate,
  intermediate: LEVELS.intermediate,
  upper_intermediate: LEVELS.upper_intermediate,
  advanced: LEVELS.advanced,
};

export const HomePage: React.FC<HomePageProps> = () => {

  const [selected, setSelected] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const questions = useQuestions();
  const tests = useTests();
  const history = useHistory();

  const handleSubmit = async () => {
    if (selected) {
      setLoading(true);
      const level = levels[selected];
      if (level) {
        const list = await questions.findByLevel(levels[selected]);
        const LIMIT = 20;
        const id = await tests.create({
          list: list.slice(0, LIMIT),
          level
        });

        history.push(`${ROUTES.TEST}/${id}`);

        Alert.success('Test created');

      }
    }

  };

  return (
    <FlexboxGrid justify="center" align="middle">
      <FlexboxGrid.Item componentClass={Col} xs={24} sm={24} md={12}>
        <Container style={{padding: 20}}>
          <Panel bordered>
            <ButtonToolbar>
              {Object.entries(levels).map(([id, name]) => {
                return (
                  <Button
                    key={id}
                    appearance={selected === id ? 'primary' : 'default'}
                    block
                    onClick={() => setSelected(id)}
                  >
                    {name}
                  </Button>
                );
              })}
            </ButtonToolbar>
            <br/>
            <FlexboxGrid justify="center" align="middle">
              <FlexboxGrid.Item colspan={8}>
                <Button block disabled={!selected} onClick={handleSubmit}> Submit </Button>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Panel>
          <br/>
          <Panel header={tests.list.length ? 'Your tests' : ''} bordered>
            <List>
              {tests.list.map((item, index) => (
                <List.Item key={item.id} index={index}>
                  <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={8}>{item.level}</FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                      {item.complete && <Tag color="green">completed</Tag>}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                      <Button onClick={() => history.push(`${ROUTES.TEST}/${item.id}`)} appearance="ghost">open</Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>

                </List.Item>
              ))}
            </List>
          </Panel>
        </Container>

      </FlexboxGrid.Item>
    </FlexboxGrid>
  );

};