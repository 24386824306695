import React from 'react';
import './App.css';
import { Logo } from 'components';
import { Affix, Avatar, Col, Container, Dropdown, Icon, Nav, Navbar, Panel } from 'rsuite';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useAuth, withAuthContext } from './contexts/authContext';
import { Login } from 'pages/Login';
import { HomePage } from 'pages/HomePage';
import { TestPage } from './pages/TestPage';
import { useRedirect } from 'hooks/useRedirect';
import { ReviewPage } from 'pages/ReviewPage';

export enum ROUTES {
  LOGIN = '/login',
  HOME = '/home',
  TEST = '/test',
  REVIEW = '/review',
}

const App = () => {
  const auth = useAuth();

  const redirect = useRedirect();
  const handleExit = async () => {
    await auth.signOut();
    redirect(ROUTES.LOGIN);
  };

  return (
    <div>
      <Affix>
        <Navbar>
          <Navbar.Header>
            <div className="logo-container" onClick={() => redirect(ROUTES.HOME)}>
              <Logo/>
            </div>
          </Navbar.Header>
          <Navbar.Body>
            {auth.isAdmin && (
              <Nav>
                {/*  <NavLink xsHidden to={ROUTES.QUESTIONS} icon={<Icon icon="question"/>}>*/}
                {/*    <NavLinkText>*/}
                {/*      Вопросы*/}
                {/*    </NavLinkText>*/}
                {/*  </NavLink>*/}
                {/*  <NavLink to={ROUTES.TEMPLATES} icon={<Icon icon="file"/>}>*/}
                {/*    <NavLinkText>*/}
                {/*      Шаблоны*/}
                {/*    </NavLinkText>*/}
                {/*  </NavLink>*/}
                {/*  <NavLink to={ROUTES.INTERVIEWS} icon={<Icon icon="avatar"/>}>{*/}
                {/*    <NavLinkText>*/}
                {/*      Интервью*/}
                {/*    </NavLinkText>*/}
                {/*  }</NavLink>*/}
              </Nav>
            )}
            {auth && auth.user && (
              <Nav pullRight>
                <Dropdown className="user-dropdown" title={auth.user.displayName || auth.user.email} icon={
                  <Avatar
                    style={{margin: '0 10px 0 0'}}
                    circle
                    src={auth.user.photoURL}
                  />}
                >
                  {/*<Dropdown.Item*/}
                  {/*  eventKey="5" onClick={redirect(ROUTES.PROFILE)}*/}
                  {/*  icon={<Icon icon="user"/>}*/}
                  {/*>*/}
                  {/*  Профиль*/}
                  {/*</Dropdown.Item>*/}
                  <Dropdown.Item eventKey="6" onClick={handleExit} icon={<Icon icon="exit"/>}>
                    Выход
                  </Dropdown.Item>
                </Dropdown>

              </Nav>
            )}
          </Navbar.Body>
        </Navbar>
      </Affix>
      <Container>
        <Switch>
          <Route path={ROUTES.LOGIN} component={Login}/>
          <PrivateRouter path={ROUTES.HOME} component={HomePage}/>
          <PrivateRouter path={`${ROUTES.TEST}/:id`} component={TestPage}/>
          <PrivateRouter path={ROUTES.REVIEW} component={ReviewPage}/>
          <Redirect to={ROUTES.LOGIN}/>
        </Switch>
      </Container>
    </div>
  );
};


const PrivateRouter = (props: any) => {
  const auth = useAuth();

  if (auth.loading) {
    return <div>Loading</div>;
  }

  if (!auth.loading && auth.user) {
    return <Route {...props} />;
  }

  return (
    <Panel>
      Not found
    </Panel>
  );
};


export default withAuthContext(App);

const NavLinkText: React.FC<any> = ({children}) => (
  <span style={{display: 'inline-block'}}>
     <Col xsHidden>{children}</Col>
  </span>
);