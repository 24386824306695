import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase';

export const AuthContext = React.createContext<any>(null);

const TIMEOUT = 5000;

export const withAuthContext = (ComposedComponent: React.ElementType) => (props: any) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<firebase.User | null>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const loadingTimeout = setTimeout(() => setLoading(false), TIMEOUT);
    firebase && firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);

      } else {
        setUser(null);
        console.log('User is signed out');
      }
      clearTimeout(loadingTimeout);
      setLoading(false);

    });
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [setLoading]);

  // check user claims
  useEffect(() => {
    firebase && firebase.auth().currentUser?.getIdTokenResult()
      .then((idTokenResult: firebase.auth.IdTokenResult) => {
        // Confirm the user is an Admin.
        setIsAdmin(!!idTokenResult.claims.admin);
      })
      .catch((error: any) => {
        console.log(error);
        setIsAdmin(false);
      });

  }, [firebase, user]);

  useEffect(() => {
    if (!user) {
      setIsAdmin(false);
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{
      isAdmin,
      user,
      loading,
      signOut: () => firebase.auth().signOut(),
    }}>
      <ComposedComponent {...props} />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext)
};
