import React, { useEffect } from 'react';
import { Button, ButtonToolbar, Col, FlexboxGrid, Icon, Panel } from 'rsuite';
import firebase from 'firebase/app'
import { useRedirect } from '../hooks/useRedirect';
import { ROUTES } from '../App';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
interface LoginProps {
  
}


export const Login: React.FC<LoginProps> = () => {
  const redirect = useRedirect();
  const history = useHistory();

  const auth = useAuth();
  const googleSignInPopup = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    // [START auth_google_signin_popup]
    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        const credential: firebase.auth.OAuthCredential | null = result.credential;

        if (credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          history.push(ROUTES.HOME)
        }

        // ...
      }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      const credential = error.credential;
      // ...
    });
    // [END auth_google_signin_popup]
  }

  useEffect(() => {
    if (auth.user) {
      history.push(ROUTES.HOME);
    }
  }, [auth, history])

  return (
    <FlexboxGrid justify="center" align="middle" style={{ height: 'calc(100vh - 80px)' }}>
      <FlexboxGrid.Item colspan={12} style={{ width: 300 }}>
        <Panel bordered shaded>
          <ButtonToolbar>
            <Button loading={auth.loading} color="red"  block onClick={googleSignInPopup}>
              <Icon icon="google"  /> Login via Google
            </Button>
          </ButtonToolbar>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}